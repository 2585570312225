<template>
<div class="wrapper">
  <aside class="side">
    <div class="sideTop">
      <div style="background-color: #3D7EF7;border-radius: 20px;width: 35px;padding-left: 4px;padding-top: 2px;margin: 0 6px;">
        <img alt="" src="@/assets/image/application-one.png" width="30px">
      </div>
      <p>智能问答模型</p>
    </div>
    <div class="sideTitle">
      <img alt="" src="@/assets/image/all-application.png" width="20px">
      招标采购智能体
    </div>
    <div class="sideSearch">
      <el-input  placeholder="搜索历史记录"  class="searchInp"  suffix-icon="el-icon-search"   size="mini"></el-input>
      <el-button icon="el-icon-delete" circle style="margin-left: 6px;width: 45px"></el-button>
    </div>
    <div class="sideBody">
      <ul>
        <li @click="handleLi(1,'请给出近三年包含色谱仪采购的招标项目')">问题1：请给出近三年包含色谱仪采购的招标项目</li>
        <li @click="handleLi(2,'请再补充制造商相关信息')">问题2：请再补充制造商相关信息</li>
        <li @click="handleLi(3,'请列出中石油（上海）新材料研究院有限公司超高效聚合物色谱仪的投标人，帮忙区别潜在投标人和中标人')">问题3：请列出中石油（上海）新材料研究院有限公司超高效聚合物色谱仪的投标人，帮忙区别潜在投标人和中标人</li>
      </ul>
    </div>
  </aside>
  <article class="articleBody">
    <!--没有问题时候-->
    <div v-if="!status" style="padding: 0 40px;">
      <div class="emptyBodyDescribe">版本为demo，请选择左侧问题进行问答，直接输入问题暂时可能无法回答哦，我会不断努力的。</div>
      <div class="emptyBodyCard">
        <div class="cardTitle">智能问答</div>
        <div class="cardBody">
          基于大模型强大的语义识别能力理解用户的问题，再结合知识图谱分析模型进行答案的查询
          分析，最终以文字或者图表等形式直观展示复杂数据或关系，并且支持多轮问答，智能问答
          模型能够根据用户反馈进行答案的优化。智能问答可在编写采购方案、项目评审过程中、数
          据统计分析等多个场景下进行使用。
        </div>
      </div>
    </div>
    <template v-else>
      <div class="bodyTitle">
        <img alt="" src="@/assets/image/user.png" width="30px">
        <div>{{bodyTitle}}</div>
      </div>
      <div style="display: flex;margin-top: 10px;">
        <img alt="" src="@/assets/image/application-one.png" style="width: 30px;height: 30px;background-color: #3D7EF7;border-radius: 20px;margin-top: 10px;margin-right: 5px">
        <div  class="articleWrapper emptyBodyCard"  v-loading="loading">
          <table class="bodyTable" border="0" cellspacing="0" v-if="answerNum===1">
            <tr>
              <th>项目名称</th>
              <th>招标单位</th>
              <th>招标机构</th>
              <th>发布日期</th>
              <th>中标单位</th>
              <th>中标价</th>
              <th>中标日期</th>
            </tr>
            <tr>
              <td>中石油（上海）新材料研究院有限公司超高效聚合物色谱仪</td>
              <td>中石油（上海）新材料研究院有限公司</td>
              <td>中国石油物资有限公司</td>
              <td>2024年3月22日</td>
              <td>朗博国际有限公司</td>
              <td>CIF Shanghai US$134,000.00</td>
              <td>April 22, 2024</td>
            </tr>
            <tr>
              <td>中石油（上海）新材料研究院有限公司超高效液相色谱仪</td>
              <td>中石油（上海）新材料研究院有限公司</td>
              <td>中国石油物资有限公司</td>
              <td>2023年12月28日</td>
              <td>新泰科（香港）有限公司投标文件</td>
              <td>CIF Shanghai US$158,900.00</td>
              <td>January 23, 2024</td>
            </tr>
          </table>
          <table class="bodyTable" border="0" cellspacing="0" v-if="answerNum===2">
            <tr>
              <th width="300">项目名称</th>
              <th>招标单位</th>
              <th>招标机构</th>
              <th>发布日期</th>
              <th>中标单位</th>
              <th>中标价</th>
              <th>中标日期</th>
              <th>制造商、国家/地区</th>
            </tr>
            <tr>
              <td>中石油（上海）新材料研究院有限公司超高效聚合物色谱仪</td>
              <td>中石油（上海）新材料研究院有限公司</td>
              <td>中国石油物资有限公司</td>
              <td>2024年3月22日</td>
              <td>朗博国际有限公司</td>
              <td>CIF Shanghai US$134,000.00</td>
              <td>April 22, 2024</td>
              <td>Waters/美国</td>
            </tr>
            <tr>
              <td>中石油（上海）新材料研究院有限公司超高效液相色谱仪</td>
              <td>中石油（上海）新材料研究院有限公司</td>
              <td>中国石油物资有限公司</td>
              <td>2023年12月28日</td>
              <td>新泰科（香港）有限公司投标文件</td>
              <td>CIF Shanghai US$158,900.00</td>
              <td>January 23, 2024</td>
              <td>Waters/美国</td>
            </tr>
          </table>
          <table class="bodyTable" border="0" cellspacing="0" v-if="answerNum===3">
            <tr>
              <th rowspan="2">投标人名称</th>
              <th colspan="4">基本信息</th>
              <th colspan="3">财务信息</th>
              <th colspan="2">发布日期</th>
              <th rowspan="2">是否中标</th>
            </tr>
            <tr>
              <th>企业类型</th>
              <th>法人/负责人</th>
              <th>成立时间</th>
              <th>总部地址</th>
              <th>注册资本</th>
              <th>总资产</th>
              <th>资产负债</th>
              <th>近3年营业额</th>
              <th>近3年业绩信息</th>
              <!--<th>中标日期</th>-->
            </tr>
            <tr>
              <td>楚斯（香港）科技有限公司</td>
              <td>代理商</td>
              <td>周令巍</td>
              <td>2005年10月22日</td>
              <td>中国香港旺角彌敦道625號雅蘭中心 二期909H室</td>
              <td>1000000.00EUR</td>
              <td>1,200,000.00EUR</td>
              <td>0</td>
              <td>
                <p>1、2020年营业额：2,400,000.00EUR</p>
                <p>2、2021年营业额：1,600,000.00EUR</p>
                <p>3、2022年营业额：850,000.00EUR</p>
              </td>
              <td>
                <p>1、中国石油物资有限公司购买总硫总氮分析仪；</p>
                <p>2、派克森更优先公司购买全自动饱和蒸气压测定仪；</p>
                <p>3、南通詹鼎材料科技有限公司购买超高效聚合物色谱仪</p>
              </td>
              <td>否</td>
            </tr>
            <tr>
              <td>精准国际仪器设备有限公司</td>
              <td>代理商</td>
              <td>习卫东</td>
              <td>2004年11月05日</td>
              <td>香港弥敦路513号太和大厦10楼</td>
              <td>HKD797000</td>
              <td>HKD172445.2</td>
              <td>HKD75737176.65</td>
              <td>
                <p>1、2022年营业额：5008122</p>
                <p>2、2023年营业额：4540899</p>
              </td>
              <td>
                <p>1、广东石化分公司购买物性测试设备；</p>
                <p>2、万华化学集团购买梯度密度仪；</p>
              </td>
              <td>否</td>
            </tr>
            <tr>
              <td>朗博国际有限公司</td>
              <td>代理商</td>
              <td>陈彩霞</td>
              <td>2007年2月21日</td>
              <td>香港上环永乐街72-76永利大厦9层C室</td>
              <td>HKD 100,000.00</td>
              <td>HKD 2536461.99</td>
              <td>0</td>
              <td>
                <p>1、2020年营业额：HKD 6906054.51</p>
                <p>2、2021年营业额：HKD 3954149.00</p>
                <p>3、2022年营业额：HKD 8743261.50</p>
              </td>
              <td>
                <p>1、中国石油物资有限公司购买高压流变仪；</p>
                <p>2、中国石油天然气股份有限公司石化公司购买物性测试类仪器；</p>
              </td>
              <td>是</td>
            </tr>
          </table>
        </div>
      </div>
    </template>

    <div class="answerCard">
      <div class="answerCardTitle">
        <span style="margin-left: 6px"><i class="el-icon-folder-add"></i>文件</span>
        <span style="margin-left: 16px"><i class="el-icon-picture-outline"></i>图片</span>
        <span style="margin-left: 16px"><i class="el-icon-place"></i>指令</span>
        <span style="margin-left: 16px"><i class="el-icon-orange"></i>启用能力</span>
      </div>
      <div class="answerCardTxt">
        <el-input
            type="textarea"
            :rows="8"
            placeholder="通过cmd+回车换行;支持复制粘贴/拖拽上传图片或文件"
            v-model="textarea"
        >
        </el-input>
      </div>
    </div>

  </article>
</div>
</template>

<script>
export default {
  name: "index",
  data(){
    return {
      bodyTitle:'',
      status:false,
      textarea:'',
      answerNum:0,
      loading:false
    }
  },
  methods:{
    handleLi(num,title){
      this.loading=true
      setTimeout(()=>{
        this.status=true
        this.answerNum = num
        this.loading=false
        this.bodyTitle = title
      },1000)

    }
  }
}
</script>

<style scoped lang="scss">
.wrapper{
  height: 100vh;
  background-image: url("~@/assets/image/home_bg.jpg");
  display: flex;
  .side {
    width: 360px;
    box-shadow: 8px 8px 20px rgba(56, 75, 102, 0.1);

    .sideTop {
      display: flex;
      align-items: center;
      border-radius: 40px;
      margin: 20px 20px;
      padding: 8px;
      background-color: #e3e0ec;
      height: 32px;
      p{
        font-weight: bold;
        font-size: 18px;
        margin-left: 22px;
      }
    }
    .sideTitle{
      display: flex;
      align-items: center;
      height: 32px;
      margin: 40px 20px 20px 25px;
      font-size: 16px;
      font-weight: 500;
      img{
        padding: 16px;
      }
    }
    .sideSearch{
      display: flex;
      margin: 20px 20px;
      ::v-deep .el-input__inner{
        border-radius:20px;
        height: 40px;
        border: 1px solid #dcdfe6;
        color: #606266;
        padding: 0 15px;
        background-color: #fff;
      }
    }
    .sideBody{
      padding: 20px;
      ul{
        list-style: none;
        li{
          cursor: pointer;
          line-height: 32px;
          font-size: 16px;
          font-weight: 500;
          margin-top:50px;
          color:#555
        }
      }
    }
  }
  .articleBody {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content:space-around;
    padding:20px 80px;
    .bodyTitle{
      display: flex;
      align-items: center;
      margin-top: 20px;
      font-weight: bold;
      div{
        margin-left: 6px;
      }
    }
    .articleWrapper{
      //height: 60%;
      background-color: #fff;
      min-height: 380px;
      //border-radius: 10px;
      //border: 2px solid #FFF;
    }

    //width: 100%;
    .emptyBodyDescribe{
      color: #f4990b;
      font-weight: bold;
      font-size: 20px;
      margin-top: 40px;
      width: 100%;
    }
    .emptyBodyCard{
      overflow: auto;
      margin-top: 20px;
      display: flex;
      flex-wrap:wrap;
      padding: 24px;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      border-radius: 10px;
      border: 2px solid #FFF;
      background: rgba(255, 255, 255, 0.40);
      .cardTitle{
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }
      .cardBody{
        height: 120px;
        margin: 16px 0 8px 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
      }
    }
    .answerCard{
      margin: 40px 40px 0 40px;
      padding: 24px;
      gap: 16px;
      align-self: stretch;
      border-radius: 10px;
      border: 2px solid #9ca3f5;
      background: rgba(255, 255, 255, 0.40);
      .answerCardTitle{
        color: #999;
        font-size: 14px;
        margin-bottom: 10px;
        span{
          cursor: pointer;
        }
      }
      .answerCardTxt{
        border-radius: 4px;
      }
    }
  }
  table {
    border-collapse: separate;
    border-top: 1px solid #eaedf4;
    border-left: 1px solid #eaedf4;
  }
  tr th {
    font-size: 14px;
    color: #8f9298;
    background-color: #f5f5f5;
    padding: 6px;
    text-align: center;
    border-bottom: 1px solid #eaedf4;
    border-right: 1px solid #eaedf4;
    height: 34px;
  }
  tr td {
    font-size: 12px;
    color: #606266;
    background-color: #fff;
    padding: 6px;
    text-align: center;
    height: 34px;//设置单元格最小高度
    border-bottom: 1px solid #eaedf4;
    border-right: 1px solid #eaedf4;
  }
}
</style>
